import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store, { key } from "./store";
import "@/static/css/reset.css";
import "@/static/css/rem.css";

import Vant from "vant";
import "vant/lib/index.css";

import addPrototype from "@/prototype";

import "./dynamicMain.js";

const app = createApp(App);

addPrototype(app);

app.use(store, key);

app.use(router);

app.use(Vant);

app.mount("#app");
