import request from "@/request";

export function list4Student() {
  return request({
    method: "get",
    url: "/api-user-acl/mp/school/list4Student",
  });
}

export function modifyStudentInfo(data) {
  return request({
    method: "post",
    url: "/api-internet-user/mp/maskbook/student/modify",
    data,
  });
}

export function getStudentInfo() {
  return request({
    method: "get",
    url: "/api-internet-user/mp/maskbook/student/getInfo",
  });
}
export function changeMessageSendMark(data) {
  return request({
    method: "post",
    url: `/api-internet-user/mp/maskbook/student/acceptMaskBookMarkedMessage`,
    data,
  });
}

export function getTeacherInfoByIds(ids) {
  return request({
    method: "GET",
    url: `/api-user-acl/mp/school/teacher/getByIds`,
    params: {
      ids: ids.join(","),
    },
  });
}
