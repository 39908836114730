import { getzdChildV2 } from "@/API/dictionary";

var zdIds = {
  version: "answercard.basicbook.version",
  course: "answercard.basicbook.course",
  grade: "answercard.basicbook.grade",
  publisher: "answercard.basicbook.publisher",
  category: "answercard.basicbook.category",
  phase: "",
};
var listTypeInfo = {
  volume: [
    { key: "上册", value: "1" },
    { key: "下册", value: "2" },
    { key: "全一册", value: "3" },
  ],
};

export async function initZidian(extend = []) {
  const data = [];
  const requestKey = [];
  extend.forEach(({ key, value }) => {
    if (!zdIds[key]) {
      zdIds[key] = value.parentId;
    }
  });
  Object.keys(zdIds).forEach((i) => {
    if (!listTypeInfo[i]) {
      data.push({ parentId: zdIds[i], recursiveTimes: 1 });
      requestKey.push(i);
    }
  });
  if (data.length === 0) {
    return listTypeInfo;
  }
  const res = await getzdChildV2(data);
  if (res.status === 200) {
    const data = res.data;
    requestKey.forEach((i) => {
      listTypeInfo[i] = data[zdIds[i]].map((obj) => {
        obj.key = obj.chName;
        return obj;
      });
    });
    return listTypeInfo;
  }
}

export function getJoinName(data, zidian = listTypeInfo) {
  let joinName = "";
  let joinNameHtml = "";
  let joinNameHtmlAndName = "";
  let joinNameAndName = "";
  let versionName = "";
  let gradeName = "";
  let courseName = "";
  let volumeName = "";
  const isEq = (t, i) => String(i) === String(t);
  data.versions = [data.version];
  data.grades = [data.grade];
  // 版本
  if (data.versions.includes("0") || data.versions.includes(0)) {
    versionName = "适用全部版本";
  } else {
    data.versions.forEach((item) => {
      zidian.version.forEach((y) => {
        if (isEq(item, y.value)) {
          versionName += y.key || y.chName;
        }
      });
    });
  }
  // 科目
  zidian.course.forEach((item, index) => {
    if (isEq(data.course, item.value)) {
      courseName += item.key || item.chName;
    }
  });
  // 年级
  zidian.grade.forEach((item) => {
    if (data.grades) {
      data.grades.forEach((_item) => {
        if (isEq(_item, item.value)) {
          gradeName += item.key || item.chName;
        }
      });
    }
  });
  // 册次
  zidian.volume.forEach((item) => {
    if (isEq(data.volume, item.value)) {
      volumeName += item.key || item.chName;
    }
  });
  // 图书名称
  const bookPropsName = versionName + gradeName + courseName + volumeName;
  joinNameHtml = `${bookPropsName}<span style="color:#1890ff">${
    data.abbrName || ""
  }</span>`;
  joinNameHtmlAndName =
    data.name ||
    `${bookPropsName}<span style="color:#1890ff">${data.abbrName || ""}</span>`;
  joinNameAndName = data.name || bookPropsName + (data.abbrName || "");
  joinName = bookPropsName + (data.abbrName || "");

  return {
    joinNameHtml,
    joinName,
    joinNameHtmlAndName,
    joinNameAndName,
    versionName,
    gradeName,
    courseName,
    volumeName,
  };
}

// 处理图书版本名称
export function getBookVersionsName(zidian, data, length) {
  if (zidian.length < 1) return;
  if (data.includes(0) || data.includes("0")) return "适用全部版本";
  var bookVersionsList = data.map(
    (item) =>
      zidian.find((y) => +item === +y.value).key ||
      zidian.find((y) => +item === +y.value).chName
  );
  if (length) {
    return `${bookVersionsList.slice(0, length - 1).join(",")},${
      bookVersionsList[length - 1][0]
    }...`;
  } else {
    return bookVersionsList.join(",");
  }
}

export function formatVersionsName(value) {
  return value.includes(0) ? "适用全版本" : value;
}
