const isLocal =
  window.location.hostname === "localhost" ||
  /^[\d.]+$/.test(location.hostname);
export default {
  hebei: {
    host: window.location.host,
  },
  shanxi: {
    host: window.location.host,
  },
  rrt: {
    host: isLocal ? "dev.zijinshe.com" : window.location.host,
  },
  liaoning: {
    host: isLocal ? "liaoning.leziedu.com" : window.location.host,
  },
};
