import request from "@/request";
import { getPlatform, urlSearch } from "./zjs";
import platform from "@/platform";

const prd = platform.isProduction;

export function ossUrl(url, cache = false) {
  const baseUrl = prd
    ? "https://prd.oss.leziedu.com"
    : "https://dev.oss.leziedu.com";
  let ret = url;
  if (typeof ret !== "string") {
    return "";
  }
  if (ret.indexOf("blob") > -1) {
    return ret;
  }
  if (ret.includes("http")) {
    return ret;
  }
  ret = baseUrl + (ret[0] === "/" ? "" : "/") + ret;
  if (cache) {
    if (typeof cache !== "boolean") {
      ret = ret + "?" + cache;
    } else {
      ret = ret + "?" + new Date().valueOf();
    }
  }
  return ret;
}
export function ossOriginUrl(url, params = {}, cache = false) {
  const baseUrl = prd
    ? "https://zijinshe-common-object.oss-cn-shenzhen.aliyuncs.com"
    : "https://test-zijinbook-2.oss-cn-shenzhen.aliyuncs.com";

  let ret = url;
  ret = baseUrl + (ret[0] === "/" ? "" : "/") + ret;
  if (cache) {
    if (typeof cache !== "boolean") {
      params.cache = cache;
    } else {
      params.cache = new Date().valueOf();
    }
  }
  const search = urlSearch(params);
  ret = ret + search;
  return ret;
}
export function base64(str) {
  return window.btoa(unescape(encodeURIComponent(str)));
}

export function debase64(str) {
  return decodeURIComponent(escape(window.atob(str)));
}
export function ossSQLPost(url, sql) {
  // const sql = `select count(*) from ossobject[*] `

  const xml = `<SelectRequest><Expression>${base64(sql)}</Expression>
      <OutputSerialization>
      <JSON>
              <RecordDelimiter>LA==</RecordDelimiter>
          </JSON>
          <OutputRawData>true</OutputRawData></OutputSerialization>
    </SelectRequest>`;
  return request
    .post(
      ossOriginUrl(url, {
        "x-oss-process": "json%2Fselect",
      }),
      xml,
      {
        headers: {
          "Content-Type": "application/xml",
        },
      }
    )
    .then((res) => JSON.parse("[" + res.data.slice(0, -1) + "]"));
}
function creatTypestr(value) {
  let ret;
  switch (typeof value) {
    case "number":
      ret = `${value}`;
      break;
    case "string":
      ret = `'${value}'`;
      break;
    default:
      break;
  }
  return ret;
}
export function createSQL(arr) {
  return arr.reduce((prev, curr, index) => {
    const [filed, ifcond, value, outBook] = curr;
    let x;
    switch (ifcond) {
      case "=":
        x = creatTypestr(value);
        break;
      // gradeStr 只适用与带,查询
      case "like":
        x = `'%${value}%'`;
        break;
      // 通用like查询
      case "likein":
        x = `'%${value}%'`;
        break;
      case "in":
        x = `(${value.map((i) => `${creatTypestr(i)}`).join(",")})`;
        break;
      default:
        break;
    }
    // 模糊查询 只能用like 字段， 通过判断likein 转为like
    return !outBook
      ? prev +
          ` ${index === 0 ? "" : "AND"} o.book.${filed} ${
            ifcond === "likein" ? "like" : ifcond
          }  ${x}`
      : prev +
          ` ${index === 0 ? "" : "AND"} o.${filed} ${
            ifcond === "likein" ? "like" : ifcond
          } ${x}`;
  }, `SELECT * FROM ossobject [*] o ${arr.length <= 0 ? "" : "WHERE"} `);
}

// 页码图片水印添加，用样式style实现,
export function getPageWaterUrl(url, style) {
  const platform = getPlatform().platform;
  const pageStype = `${platform}_page_water`;
  return url + "?x-oss-process=style/" + pageStype;
}

// 切题水印图片
export function getCutWaterUrl(url) {
  const platform = getPlatform().platform;
  const dealStr = (str = "") =>
    str
      .replaceAll("+", "-")
      .replaceAll("/", "_")
      .substring(0, str.length - 1);
  const text = {
    hebei: dealStr(base64("微信号：zijinshe")),
    shanxi: dealStr(base64("微信号：xinhualezi")),
    rrt: dealStr(base64("微信号：zijinshe")),
    liaoning: dealStr(base64("微信号：zijinshe")),
  };
  const style = `image/auto-orient,1/quality,q_90/watermark,text_${text[platform]},color_000000,size_20,rotate_337,fill_1,t_10,x_10,y_10`;
  return url + "," + style;
}
// 切题图片
export function getOssImgCutUrl(attachItem, answer = true, water = true) {
  const page = attachItem.page || attachItem;
  const answerKey = page.pageAnswerPicPath
    ? "pageAnswerPicPath"
    : "answerPicPath";
  const blankKey = page.pageBlankPicPath ? "pageBlankPicPath" : "blankPicPath";
  const url = `${ossUrl(
    page[answer ? answerKey : blankKey]
  )}?x-oss-process=image/crop,x_${parseInt(attachItem.ltx)},y_${parseInt(
    attachItem.lty
  )},w_${parseInt(attachItem.width)},h_${parseInt(attachItem.height)}`;
  return water ? getCutWaterUrl(url) : url;
}
