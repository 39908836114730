import { Router } from "vue-router";
import { loginWx } from "@/permisson/wxOauth2";
import store from "@/store";

export default function getNecessaryData(router: Router): void {
  router.beforeEach(async (to, from, next) => {
    await store.dispatch("smartHomeworkUserClient/user/getStudentUserInfo");
    next();
  });
}
