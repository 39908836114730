import request from "@/request";
import {
  delCookie,
  getCookie,
  getQueryStr,
  removeURLParameter,
  setCookie,
} from "@/static/js/zjs";
import platform from "@/platform";

export async function getWxOauth2Code(): Promise<string> {
  const code = getQueryStr("code");
  if (code) return code;
  const data: any = await request({
    url:
      `/api-internet-user/open/weixin/ghaccount/oauth2/url/` +
      platform.currentPlatformAppid,
    params: {
      redirect_url: encodeURIComponent(window.location.href),
      state: "snsapi_base",
      scope: "snsapi_base",
    },
  });
  window.location.href = data;
  return Promise.reject();
}

export async function loginWithWxOauth2(isRequest?: boolean): Promise<string> {
  const openid = getCookie("openid");
  const token = getCookie("token");
  if (openid && token && !isRequest) return openid;
  // 需要主动重新授权opened时，删除cookie
  if (isRequest) {
    delCookie("openid");
  }
  const code = await getWxOauth2Code();
  // 获取token、openid
  const res = await request({
    url: "/api-internet-user/open/weixin/ghaccount/oauth2/getJwtTokenByCode",
    params: {
      appid: platform.currentPlatformAppid,
      code,
      state: getQueryStr("state"),
    },
  });
  if (res.status === 200) {
    setCookie("openid", res.data.openid);
    setCookie("token", res.data.token);
    return res.data.openid;
  } else if (res.status === 2010) {
    // code 异常，移除并重新获取
    window.location.href = removeURLParameter(window.location.href, "code");
    return Promise.reject();
  } else {
    return Promise.reject();
  }
}

export async function loginWithWxOauth2Development(
  isRequest?: boolean
): Promise<string> {
  // 获取openid
  const openid = "ost3Is0EqskJaV0dvCdGG3QoDLNk";
  const token = getCookie("token");

  // 有openid且有token，且不需强制发送，不用重新获取，token过期交由request处理
  if (openid && token && !isRequest) return openid;

  // 获取jwt，测试环境专属，无需code
  const res = await request({
    url: "/api-internet-user/open/weixin/ghaccount/test/getJwtTokenByCode",
    params: {
      appid: platform.currentPlatformAppid,
      openid,
      state: getQueryStr("state"),
    },
  });
  if (res.status === 200) {
    setCookie("openid", res.data.openid);
    setCookie("token", res.data.token);
    return res.data.openid;
  } else {
    return Promise.reject();
  }
}

export const loginWx = platform.isBuild
  ? loginWithWxOauth2
  : loginWithWxOauth2Development;
