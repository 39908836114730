import request from "@/request";

export function getAliVideoUrl(data) {
  return request
    .get(`/api-user-acl/open/video/aliyun/findVideoByIds`, {
      params: {
        ids: data,
      },
    })
    .then((res) => res.data);
}
