const requestAllConfig = {
  development: {
    zuul: "https://dev.zuul.leziedu.com",
    oss: "https://dev.oss.leziedu.com",
    ossBucketUrl: "https://test-zijinbook-2.oss-cn-shenzhen.aliyuncs.com",
    nfzijin: "https://nfzijin.leziedu.com",
  },
  staging: {
    zuul: "https://dev.zuul.leziedu.com",
    oss: "https://dev.oss.leziedu.com",
    ossBucketUrl: "https://test-zijinbook-2.oss-cn-shenzhen.aliyuncs.com",
    nfzijin: "https://nfzijin.leziedu.com",
  },
  production: {
    zuul: "https://prd.zuul.leziedu.com",
    oss: "https://prd.oss.leziedu.com",
    ossBucketUrl: "https://zijinshe-common-object.oss-cn-shenzhen.aliyuncs.com",
    nfzijin: "https://weixin.nfzijin.com",
  },
};

// TODO 请求前缀，留待以后使用，用于开发服务代理
export const requestPrefix = {};

const requestConfig = requestAllConfig[process.env.VUE_APP_BUILD_ENV];

export default requestConfig;
