import { RouteRecordRaw } from "vue-router";
import smartHomeworkUserClient from "@/project/smartHomeworkUserClient/views/index.vue";
import loginSnsapiBaseRouterPlugin from "@/permisson/loginSnsapiBaseRouterPlugin";
import getNecessaryData from "@/project/smartHomeworkUserClient/permisson/getNecessaryData";
import router from "@/router";

const smartHomeworkUserClientRoutes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/smartHomeworkUserClient",
  },
  {
    path: "/smartHomeworkUserClient",
    component: smartHomeworkUserClient,
    meta: {
      title: "智慧作业",
    },
  },
  {
    path: "/smartHomeworkUserClient/userCenter/index",
    component: () =>
      import("@/project/smartHomeworkUserClient/views/userCenter/index.vue"),
    meta: {
      title: "个人中心",
    },
  },
  {
    path: "/smartHomeworkUserClient/userCenter/messageNotify",
    component: () =>
      import(
        "@/project/smartHomeworkUserClient/views/userCenter/messageNotify.vue"
      ),
    meta: {
      title: "消息通知",
    },
  },
  {
    path: "/smartHomeworkUserClient/userCenter/studentInfo",
    component: () =>
      import(
        "@/project/smartHomeworkUserClient/views/userCenter/studentInfo.vue"
      ),
    meta: {
      title: "学生信息",
    },
  },
  {
    path: "/smartHomeworkUserClient/statisticalData/wrongBooks",
    component: () =>
      import(
        "@/project/smartHomeworkUserClient/views/statisticalData/wrongBooks.vue"
      ),
    meta: {
      title: "书籍列表",
    },
  },
  {
    path: "/smartHomeworkUserClient/statisticalData/wrongBookClasses",
    component: () =>
      import(
        "@/project/smartHomeworkUserClient/views/statisticalData/wrongBookClasses.vue"
      ),
    meta: {
      title: "书籍列表",
    },
  },
  {
    path: "/smartHomeworkUserClient/checkHistory/index",
    component: () =>
      import("@/project/smartHomeworkUserClient/views/checkHistory/index.vue"),
    meta: {
      title: "批阅记录",
    },
  },
  {
    path: "/smartHomeworkUserClient/checkHistory/checkBookPage",
    component: () =>
      import(
        "@/project/smartHomeworkUserClient/views/checkHistory/checkBookPage.vue"
      ),
    meta: {
      title: "批阅记录",
    },
  },
  {
    path: "/smartHomeworkUserClient/checkHistory/PageRenderCheckTrace",
    component: () =>
      import(
        "@/project/smartHomeworkUserClient/views/checkHistory/PageRenderCheckTrace.vue"
      ),
    meta: {
      title: "批阅记录",
    },
  },
  {
    path: "/smartHomeworkUserClient/wrongQuestionsBook",
    component: () =>
      import(
        "@/project/smartHomeworkUserClient/views/wrongQuestionBook/index.vue"
      ),
    meta: {
      title: "错题记录",
    },
  },
  {
    path: "/smartHomeworkUserClient/wrongQuestionsList/:courseVal/:gradeVal/:volumnVal",
    component: () =>
      import(
        "@/project/smartHomeworkUserClient/views/wrongQuestionBook/list.vue"
      ),
    meta: {
      title: "错题本",
    },
    props: true,
  },
];

smartHomeworkUserClientRoutes.forEach((route) => {
  router.addRoute(route);
});

loginSnsapiBaseRouterPlugin(router);
getNecessaryData(router);
