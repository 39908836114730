
/*
 * By Suxue
 * 2022/3/16
 */
import { Options, Vue } from "vue-class-component";

@Options({
  name: "StudentMenuBar",
  props: {
    active: {
      type: [Number, String],
      default: 2,
    },
  },
  data() {
    return {};
  },
  computed: {
    isActivated1() {
      return Number(this.active) === 1;
    },
    isActivated2() {
      return Number(this.active) === 2;
    },
    isActivated3() {
      return Number(this.active) === 3;
    },
  },
  methods: {
    tabActive(index: number) {
      console.log(index);
      const url = [
        "/smartHomeworkUserClient",
        "/smartHomeworkUserClient/wrongQuestionsBook",
        "/smartHomeworkUserClient/userCenter/index",
      ];
      if (index !== this.active) {
        this.$router.push({
          path: url[index - 1],
        });
      }
    },
  },
})
export default class StudentMenuBar extends Vue {}
