import user from "./user";
import store from "@/store";
const smartHomeworkUserClientStore = {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
  },
};

store.registerModule("smartHomeworkUserClient", smartHomeworkUserClientStore);
