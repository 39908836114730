import { createStore, Store, useStore as baseUseStore } from "vuex";
import userStore from "@/store/user";
import { InjectionKey } from "vue";

const store = createStore({
  modules: {
    user: userStore,
  },
});

export type StoreType = typeof store;

export const key: InjectionKey<Store<typeof store.state>> = Symbol();
export default store;
export function useStore() {
  return baseUseStore(key);
}
