import axios from "axios";
import { Toast } from "vant";

import { getCookie } from "@/static/js/zjs";
import requestConfig from "@/request/config";
import platform from "@/platform";
import { loginWx } from "@/permisson/wxOauth2";
import RequestCount from "@/request/requestCount";

const request = axios.create({
  timeout: 20000,
});

// 刷新token的机制
// 公共的login Promise
let loginPromise = null;
// 避免进入无限请求循环
let requestCount = new RequestCount();
requestCount.initCountFromLocal();

function reTryRequest(config) {
  // 超过计数停止重试，应用进入错误状态
  if (requestCount.checkCount(config.url)) {
    return Promise.reject();
  }
  // 请求计数加一
  requestCount.addCount(config.url);
  console.log(requestCount);
  loginPromise = (loginPromise || loginWx(true)).then(() => {
    loginPromise = null;
    requestCount.clear();
  });
  return loginPromise.then(() => request(config));
}

// 创建正确的URL
function createRequestURL(config) {
  // 全链接直接返回
  if (config.url.startsWith("http")) {
    return config.url;
  }
  // 开发环境下由webpack代理请求
  if (!platform.isBuild && !config.noProxy) {
    return config.url;
  }
  // 构建环境下拼接全链接
  const serve = config.serve;
  if (serve) {
    return requestConfig[serve] + config.url;
  } else {
    return config.url;
  }
}

// 请求拦截器
request.interceptors.request.use(async (config) => {
  const defaultConfig = {
    // 是否出现加载框
    loading: false,
    // 是否要错误提示框
    error: true,
    // 第三方服务选择
    serve: "zuul",
  };
  config = {
    ...defaultConfig,
    ...config,
  };
  // 创建url
  config.url = createRequestURL(config);

  // 添加token
  config.headers["token"] = getCookie("token");

  return Promise.resolve(config);
});

// 响应拦截器
request.interceptors.response.use(
  (response) => {
    const res = response.data;
    console.log("请求监控", response);
    return Promise.resolve(res);
  },
  (error) => {
    const status = error.response.status;
    switch (status) {
      case 403: {
        // 处理广告json为空的报错情况
        if (error.request.responseURL.includes("/adv")) {
          return Promise.reject(error);
        }
        break;
      }
      // token 无效时，重新获取
      case 401: {
        return reTryRequest(error.response.config);
      }
      // 处理服务器502
      case 502: {
        window.location.href =
          "https://prd.oss.leziedu.com/utils/html/502.html";
        break;
      }
      default: {
        // 异常处理，调用统一异常函数
        if (!error.config.error) {
          Toast("网络异常，请稍后再试");
        }
        setTimeout(() => {
          Toast.clear();
        }, 2000);
        return Promise.reject(error);
      }
    }
  }
);
export default request;
