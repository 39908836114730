import request from "@/request";
import { formatDate } from "@/static/js/zjs";

// 7日数据
export function listGradeStudentWrongData4Nday(data) {
  const Date7 = new Date();
  Date7.setDate(Date7.getDate() - 6);
  return request({
    url: "/api-checking/mp/maskbook/student/statistics/listGradeStudentWrongData4Nday",
    method: "post",
    data: {
      schoolId: data.schoolId,
      phase: data.phase,
      enterSchoolYear: data.enterSchoolYear,
      startTime: formatDate("yyyy-MM-dd", Date7),
      endTime: formatDate("yyyy-MM-dd"),
    },
  });
}

export function listWrongQuestion4Student(data) {
  const Date7 = new Date();
  Date7.setDate(Date7.getDate() - 6);
  return request({
    url: "/api-checking/mp/maskbook/student/statistics/listWrongQuestion4Student",
    method: "post",
    data: {
      schoolId: data.schoolId,
      phase: data.phase,
      enterSchoolYear: data.enterSchoolYear,
      classNumber: data.classNumber,
      studentNumber: data.studentNumber,
      startTime: formatDate("yyyy-MM-dd"),
      endTime: formatDate("yyyy-MM-dd"),
    },
  });
}

// 批阅书籍列表
export function listWrongData4Student(data) {
  return request({
    url: "/api-checking/mp/maskbook/student/statistics/listWrongData4Student",
    method: "post",
    data: {
      schoolId: data.schoolId,
      phase: data.phase,
      enterSchoolYear: data.enterSchoolYear,
      classNumber: data.classNumber,
      studentNumber: data.studentNumber,
    },
  });
}

// 批阅书籍记录/错题统计
export function listWrongData4Book(data) {
  return request({
    url: "/api-checking/mp/maskbook/student/statistics/listWrongData4Book",
    method: "post",
    data: {
      schoolId: data.schoolId,
      phase: data.phase,
      enterSchoolYear: data.enterSchoolYear,
      classNumber: data.classNumber,
      bookId: data.bookId,
      startTime: data.startTime,
      endTime: data.endTime,
    },
  });
}

// 班级错题人数统计及明细
export function listWrongData4Question(data) {
  return request({
    url: "/api-checking/mp/maskbook/student/statistics/listWrongData4Question",
    method: "post",
    data: {
      schoolId: data.schoolId,
      phase: data.phase,
      enterSchoolYear: data.enterSchoolYear,
      classNumber: data.classNumber,
      bookId: data.bookId,
      startTime: data.startTime,
      endTime: data.endTime,
    },
  });
}

//书籍页码批阅统计-带页码列表页
export function getBookWrongData4book(data) {
  return request({
    url: "/api-checking/mp/maskbook/student/statistics/getBookWrongData4Book",
    method: "post",
    data,
  });
}

// 查询页批阅识别结果-历史记录
export function listRecognitionForPageHistory(data) {
  return request({
    url: "/api-checking/mp/maskbook/mark/question/listRecognitionForPageHistory",
    method: "post",
    data,
  });
}

export function getQuestionInfoByIds(data) {
  return request({
    url: `/api-answer-card/gh/question-extrapolation/getByIds/${
      data.sourceType || "basic"
    }`,
    method: "GET",
    params: {
      ids: data.ids,
    },
  });
}
