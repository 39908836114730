import RootState, { UserState } from "@/store/typing";
import { Module } from "vuex";

const state: UserState = {
  openid: "",
};

const userStore: Module<UserState, RootState> = {
  state,
  mutations: {
    setOpenid(state, payload) {
      state.openid = payload;
    },
  },
};

export default userStore;
