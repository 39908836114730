export default class RequestCount {
  maxCount = 5;
  count: Record<string, number> = {};

  initCountFromLocal(): void {
    const requestCount = window.localStorage.getItem("requestCount") || "{}";
    this.count = JSON.parse(requestCount);
  }

  addCount(key: string, num?: number): boolean {
    if (num !== undefined) {
      this.count[key] = +num;
    } else {
      this.count[key] = (this.count[key] || 0) + 1;
    }
    // 同步到localStorage
    window.localStorage.setItem("requestCount", JSON.stringify(this.count));

    // 超出计数则停止，返回false
    return this.checkCount(key);
  }

  checkCount(key?: string): boolean {
    if (key) {
      return Number(this.count[key]) > this.maxCount;
    } else {
      return Object.values(this.count).some((k) => Number(k) > this.maxCount);
    }
  }

  clear(): void {
    this.count = {};
    window.localStorage.removeItem("requestCount");
  }
}
