import {
  getPlatformInOpen,
  getPlatformInThird,
  PLATFORM_NAME,
} from "@/platform/util";

const modulesFiles = require.context("./", true, /\.js$/);

const getMoudelValue = (value) => {
  if (value.default) {
    const a = {
      ...value,
      ...value.default,
    };
    delete a.default;
    return a;
  } else {
    return value;
  }
};
// 局部变量
// const extraModulesFiles = require.context('../../src', true, /VuexStore\/.+?\.js$/)
const { platform, appid } = getPlatformInThird() || getPlatformInOpen();
const isDev = [PLATFORM_NAME.RENRENTONG, PLATFORM_NAME.LIAONING].includes(
  platform
);
// 赋值相应的平台
let renderPlatform = platform;
// 人人通/辽宁 对应 河北/山西 的配置。可以修改以适应实际需求
if (platform === PLATFORM_NAME.RENRENTONG) {
  renderPlatform = PLATFORM_NAME.HEBEI;
} else if (platform === PLATFORM_NAME.LIAONING) {
  renderPlatform = PLATFORM_NAME.SHANXI;
}
const Modules = modulesFiles.keys().reduce((preValue, modulePath) => {
  if (!modulePath.includes("index.js")) {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
    const moduleValue = modulesFiles(modulePath);
    // 如果不存在对应平台的配置，默认选取河北配置
    // 如果读取不到配置，则选取整个对象应用为配置
    const value = getMoudelValue(moduleValue);
    let selfPlatform = renderPlatform;
    // 新增规则，如果配置了测试环境的变量，则应用测试环境，否则遵循以上规则
    if (isDev && value[platform]) {
      selfPlatform = platform;
    }
    preValue[moduleName] =
      value[selfPlatform] || value[PLATFORM_NAME.HEBEI] || value;
    // 赋值变量平台，以方便检查
    try {
      preValue[moduleName].platform = value[selfPlatform]
        ? selfPlatform
        : PLATFORM_NAME.HEBEI;
    } catch (e) {
      console.error(e);
    }
    return preValue;
  }
  return preValue;
}, {});

Modules.renderPlatform = renderPlatform;
Modules.currentPlatform = platform;
Modules.currentPlatformAppid = appid;
Modules.isBuild = process.env.VUE_APP_BUILD_ENV !== "development";
Modules.isProduction = process.env.VUE_APP_BUILD_ENV === "production";
// console.log(Modules)
export default Modules;
