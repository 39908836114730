import axios from "@/request";
import { createSQL, ossSQLPost, ossUrl } from "../static/js/oss";
import { getPlatform } from "@/static/js/zjs.js";
import { getQueryStr } from "../static/js/zjs";

const appid = getPlatform().appid;
const jsonPath = {
  toolBook: ["keben", "jiaofu"],
  examPaper: [
    "mijuan_qizhongqimo",
    "mijuan_danyuanceshi",
    "mijuan_shengxuekaoshi",
    "mijuan_zhuantishijuan",
  ],
  listenBook: ["kebendiandu", "huibendiandu", "mingzhuyuedu"],
  readBook: ["tingxie", "yuedu", "zhishidian"],
  holidayBook: ["shujiazuoye", "hanjiazuoye"],
};

/**
 * 书籍列表
 * @param {*年级} grade
 * @param {*学期} volume
 * @param {*版本} version
 */

export async function getOssBookList(data, filter) {
  const { category, grade, volume, version, course, categoryLike, display } =
    data;
  const filterArr = [
    category && ["category", "=", category],
    grade && ["gradesStr", "like", `${grade}` + ","],
    categoryLike && ["category", "likein", categoryLike],
    course && ["course", "=", course],
    display && ["display", "=", display, true],
    volume && ["volume", "in", [`${volume}`, "3"]],
    version && ["version", "=", version],
  ]
    .concat(filter)
    .filter(Boolean);
  const sql = createSQL(filterArr);
  // 确定类型json
  const categoryArr = filterArr.find((i) => i[0] === "category") || [];
  const query = categoryArr[1];
  const categoryAll = categoryArr[2];
  // 没有类型
  if (!categoryAll) {
    console.error("指定要查询的类型");
    return [];
  }
  // 找类型
  let jsonCategory;
  const categoryOne =
    typeof categoryAll === "string" ? categoryAll : categoryAll[0];
  for (const key in jsonPath) {
    if (
      !jsonCategory &&
      jsonPath[key].some((i) => {
        if (query === "like") {
          return i.includes(categoryOne);
        } else {
          return i === categoryOne;
        }
      })
    ) {
      jsonCategory = key;
      break;
    }
  }
  if (!jsonCategory) {
    console.error("不存在的书籍类型");
    return [];
  }
  const bookDir = {
    holidayBook: "holidaybook",
  };
  return ossSQLPost(
    `/${
      bookDir[jsonCategory] || "basicbook"
    }/${appid}/bookListInfo_${jsonCategory}.json`,
    sql
  );
}
export function getOssBookJSON(bookId) {
  let isNoCache;
  if (window && window.location) {
    isNoCache = getQueryStr("noCache_oss", window.location.search);
  }
  var url = `/basicbook/${appid}/${bookId}_bookInfo.json${
    isNoCache ? "?" + new Date().valueOf() : ""
  }`;
  return axios.get(ossUrl(url)).then((res) => res.data);
}

export function getOssHolidayBookJSON(bookId) {
  let isNoCache;
  if (window && window.location) {
    isNoCache = getQueryStr("noCache_oss", window.location.search);
  }
  var url = `/holidaybook/${appid}/${bookId}_bookInfo.json${
    isNoCache ? "?" + new Date().valueOf() : ""
  }`;
  return axios.get(ossUrl(url)).then((res) => res.data);
}

export async function getOssBookPageJSON(bookId, chapterId) {
  let isNoCache;
  if (window && window.location) {
    isNoCache = getQueryStr("noCache_oss", window.location.search);
  }
  var url = `/basicbook/${bookId}/chapter/${chapterId}_allInfo.json${
    isNoCache ? "?" + new Date().valueOf() : ""
  }`;
  try {
    var res = await axios
      .get(ossUrl(url), {
        notShowToast: true,
      })
      .then((res) => res.data);
  } catch (error) {
    const XHRres = await getOssBookPageJSONByXHR(bookId, chapterId);
    res = XHRres[0];
  }
  return res;
}
export async function getOssBookQuestionJSON(bookId, chapterId, questionId) {
  let isNoCache;
  if (window && window.location) {
    isNoCache = getQueryStr("noCache_oss", window.location.search);
  }
  var url = `/basicbook/${bookId}/chapter/${chapterId}/question/${questionId}_allInfo.json${
    isNoCache ? "?" + new Date().valueOf() : ""
  }`;
  try {
    var res = await axios.get(ossUrl(url)).then((res) => res);
  } catch (error) {
    res = await getOssBookQuestionJSONByXHR(bookId, chapterId, questionId);
  }
  return res;
}
export function getOssBookPageJSONByXHR(bookId, chapterIds) {
  return axios
    .get("/mopa/api-answer-card/open/basicbook/get-chapter-jsons", {
      params: {
        bookId,
        chapterIds,
      },
    })
    .then((res) => res.data);
}
export function getOssBookQuestionJSONByXHR(bookId, chapterId, questionId) {
  var url = `/mopa/api-answer-card/open/basicbook/get-question-json/${questionId}`;
  return axios
    .get(url, {
      params: {
        bookId,
        chapterId,
      },
    })
    .then((res) => res.data);
}
