import { App } from "vue";
import platform from "@/platform";
import request from "@/request";

export default function addPrototype(app: App<Element>): void {
  app.config.globalProperties.$platform = platform;
  app.config.globalProperties.$isBuild = platform.isBuild;
  app.config.globalProperties.$isProduction = platform.isProduction;
  app.config.globalProperties.$request = request;
}
