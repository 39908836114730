<template>
  <div class="statisticalDataWrap">
    <div class="userCard">
      <div class="flex-row flex-al-center">
        <div class="userHead">
          <img
            src="https://img01.yzcdn.cn/upload_files/2022/05/09/FtsbKt6KzimEwA4-SEPctMiABLiy.png"
          />
        </div>
        <div class="userMessage" v-if="StudentInfo.school">
          <div class="userM1">
            <span>{{ StudentInfo.name }}</span>
            <span>{{ StudentInfo.school.name }}</span>
          </div>
          <div class="userM2">
            <span>{{ StudentInfo.gradeName }}</span>
            <span>{{ StudentInfo.classNumber }}班</span>
            <span>学号{{ StudentInfo.studentNumber }}</span>
          </div>
        </div>
        <button
          v-if="!loading && !StudentInfo.school"
          @click="toLogin"
          class="loginBut"
        >
          立即登录
        </button>
      </div>
    </div>
    <div class="dataContent">
      <!--  头部切换  -->
      <div class="changeTitle flex-row flex-al-center flex-jc-bt">
        <img
          class="tImg"
          src="https://img01.yzcdn.cn/upload_files/2022/05/09/FoOMF--KudCE1sC0lJx93qrJ3MJT.png"
        />
        <div class="courseSelect">
          <div
            v-for="(item, index) in courseSelectList"
            :key="item.course"
            class="tabItem flex-center"
            @click="courseSelectChange(index)"
            :class="{ selected: index === courseSelectIndex }"
          >
            {{ item.courseName }}
          </div>
        </div>
      </div>
      <!--  正确率击败情况  -->
      <div class="rightRate">
        <div class="rightData flex-row flex-al-center">
          <div class="dataContentWrap flex-col flex-al-center">
            <p>{{ currentData.rightPercent }}%</p>
            <p>我的正确率</p>
          </div>
          <div class="line"></div>
          <div class="dataContentWrap flex-col flex-al-center">
            <p>{{ currentData.wrongCt }}</p>
            <p>我的错题</p>
          </div>
          <div class="line"></div>
          <div class="dataContentWrap flex-col flex-al-center">
            <p>{{ currentData.markQuestionCt }}</p>
            <p>我的已批题目</p>
          </div>
        </div>
        <div class="rightRank">
          <img
            src="https://img01.yzcdn.cn/upload_files/2022/05/09/FjMxVp4Bz_OoylIp1oPHMPpKdDnt.png"
          />
          <p class="t1">你的正确率击败了</p>
          <p class="classKo left">
            <span>本班</span>
            <span>{{ currentData.koClass }}%</span>
          </p>
          <p class="classKo right">
            <span>本年级</span>
            <span>{{ currentData.koGrade }}%</span>
          </p>
        </div>
      </div>
      <!--  历史趋势  -->
      <div class="historyData">
        <div class="commonTitle flex-row flex-al-center">
          <div class="tLine"></div>
          <h3>历史趋势</h3>
        </div>
        <div class="commonContent">
          <div class="charts-box">
            <v-chart :option="chartData"></v-chart>
          </div>
        </div>
      </div>
      <!--  我的错题   -->
      <div class="historyData myQuestion">
        <div class="commonTitle flex-row flex-al-center">
          <div class="tLine"></div>
          <h3>我的错题（今日）</h3>
        </div>
        <div class="commonContent">
          <div class="questionList">
            <div
              class="questionItem"
              v-for="item in renderQuestions"
              :key="item.id"
            >
              <div class="questionName">
                {{ item.joinName }} p{{
                  item.question.attaches[0].page.pageName
                }}
                -
                {{ item.question.name }}
              </div>
              <div class="questionImages">
                <img
                  v-for="img in getCutImages(item.question.attaches)"
                  :key="img"
                  :src="img"
                  mode="widthFix"
                />
              </div>
              <div class="questionData flex-row flex-al-center flex-jc-bt">
                <div class="questionRate">
                  <p>
                    班级出错：
                    {{
                      Math.round(
                        this.toPercent(item.wrongCt, item.markQuestionCt)
                      )
                    }}%
                  </p>
                  <p>{{ item.updateTime }}</p>
                </div>
                <button
                  class="questionExplain flex-center"
                  @click="toExplain(item)"
                >
                  讲解视频
                </button>
              </div>
            </div>
          </div>
          <div class="noQuestion" v-if="!loading && renderQuestions.length < 1">
            <van-empty
              image="https://img01.yzcdn.cn/upload_files/2022/05/09/FnAgOyYLPshamYlwaginMmHIxP39.png"
              :image-size="[111, 96]"
              description="暂无错题"
            />
          </div>
        </div>
      </div>
      <VideoExplainPlayer ref="VideoExplainPlayer" />
    </div>
    <StudentMenuBar active="1" />
  </div>
</template>

<script>
import StudentMenuBar from "@/project/smartHomeworkUserClient/component/StudentMenuBar";
import { formatDate, unique } from "@/static/js/zjs";
import {
  listGradeStudentWrongData4Nday,
  listWrongQuestion4Student,
} from "@/project/smartHomeworkUserClient/API/statistical";
import { Options, Vue } from "vue-class-component";
import { use } from "echarts/core";
import { LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { GridComponent, LegendComponent } from "echarts/components";
import VChart from "vue-echarts";
import { groupBy, uniqBy } from "lodash";
import {
  initZidian,
  getJoinName,
} from "@/project/smartHomeworkUserClient/common/bookName";
import { ossUrl } from "@/static/js/oss";
import VideoExplainPlayer from "@/project/smartHomeworkUserClient/component/VideoExplainPlayer";

use([CanvasRenderer, LineChart, GridComponent, LegendComponent]);

@Options({
  components: { VideoExplainPlayer, StudentMenuBar, VChart },
  data() {
    return {
      nowData: {
        bookCt: 0,
        markQuestionCt: 0,
        studentCt: 0,
        wrongCt: 0,
        rightRate: 0,
      },
      loading: true,
      StudentInfo: {},
      courseSelectIndex: 0,
      courseSelectList: [
        {
          course: 0,
          courseName: "全部",
        },
      ],
      finalData: [],
      myQuestions: [],
    };
  },
  computed: {
    selectCourse() {
      return this.courseSelectList[this.courseSelectIndex].course;
    },
    currentData() {
      console.log(this.finalData);
      if (!this.StudentInfo.classNumber) {
        return {
          rightPercent: 100,
          wrongCt: 0,
          markQuestionCt: 0,
          koClass: 100,
          koGrade: 100,
          // 7日科目数据
          myRightPercentLine: [100, 100, 100, 100, 100, 100, 100],
          classRightPercentLine: [100, 100, 100, 100, 100, 100, 100],
          gradeRightPercentLine: [100, 100, 100, 100, 100, 100, 100],
        };
      }
      return this.finalData.find((i) => i.course === this.selectCourse) || {};
    },
    chartData() {
      const date7Day = new Array(7).fill(1).map((d, i) => {
        d = new Date();
        d.setDate(d.getDate() + i - 6);
        return formatDate("yyyy-MM-dd", d);
      });
      return {
        grid: {
          // y: "4%", // y 偏移量
          height: "60%", // 高度
        },
        legend: {
          data: ["正确率", "班级", "年级"],
        },
        xAxis: {
          type: "category",
          data: date7Day.map((i) => formatDate("MM-dd", i)),
          axisTick: {
            interval: 0,
          },
        },
        yAxis: [
          {
            type: "value",
            name: "",
            position: "left",
          },
        ],
        series: [
          {
            name: "正确率",
            data: this.currentData.myRightPercentLine,
            type: "line",
            smooth: true,
          },
          {
            name: "班级",
            data: this.currentData.classRightKoPercentLine,
            type: "line",
            smooth: true,
          },
          {
            name: "年级",
            data: this.currentData.gradeRightKoPercentLine,
            type: "line",
            smooth: true,
          },
        ],
      };
    },
    renderQuestions() {
      return this.selectCourse
        ? this.myQuestions[this.selectCourse] || []
        : Object.values(this.myQuestions).flat();
    },
  },
  async mounted() {
    this.loading = true;
    try {
      await this.$store.dispatch(
        "smartHomeworkUserClient/user/getStudentUserInfo",
        true
      );
    } catch (e) {
      console.log(e);
    }
    const StudentInfo =
      this.$store.state.smartHomeworkUserClient.user.studentUserInfo;
    this.StudentInfo = StudentInfo;
    if (!StudentInfo.id) {
      this.loading = false;
      this.finalData = {};
      return;
    }
    await this.initData({
      ...StudentInfo,
    });
    await this.initMyWrong({
      ...StudentInfo,
    });
    this.loading = false;
  },
  methods: {
    async initData(StudentInfo) {
      await initZidian();
      return listGradeStudentWrongData4Nday(StudentInfo).then((res) => {
        const nowDate = formatDate("yyyy-MM-dd");
        const date7Day = new Array(7).fill(1).map((d, i) => {
          d = new Date();
          d.setDate(d.getDate() + i - 6);
          return formatDate("yyyy-MM-dd", d);
        });
        // 本年级所有学生的7日数据，平铺展示
        const gradeAll7DaysDataFlat = res.data || [];
        // 将年级、班级、个人数据按日期进行7日分组
        const gradeAll7DaysDataDateGroup = {};
        const classAll7DaysDataDateGroup = {};
        const studentAll7DaysDataFlatDateGroup = {};
        // 最终组合的数据结构，一级按照科目分组(包含全部)
        // 归类计算 classAll7DaysDataFlat, studentAll7DaysDataFlat
        for (let studentIndex in gradeAll7DaysDataFlat) {
          const student = gradeAll7DaysDataFlat[studentIndex];
          const d = student.xday;

          const isSameClass = this.isSameClass(student, StudentInfo);
          const isSameStudent = this.isSameStudent(student, StudentInfo);

          classAll7DaysDataDateGroup[d] = classAll7DaysDataDateGroup[d] || [];
          studentAll7DaysDataFlatDateGroup[d] =
            studentAll7DaysDataFlatDateGroup[d] || [];
          gradeAll7DaysDataDateGroup[d] = gradeAll7DaysDataDateGroup[d] || [];
          gradeAll7DaysDataDateGroup[d].push(student);
          if (isSameClass) {
            classAll7DaysDataDateGroup[d].push(student);
          }
          if (isSameStudent) {
            studentAll7DaysDataFlatDateGroup[d].push(student);
          }
        }
        date7Day.forEach((d) => {
          classAll7DaysDataDateGroup[d] = classAll7DaysDataDateGroup[d] || [];
          studentAll7DaysDataFlatDateGroup[d] =
            studentAll7DaysDataFlatDateGroup[d] || [];
          gradeAll7DaysDataDateGroup[d] = gradeAll7DaysDataDateGroup[d] || [];
        });
        // 计算该学生今日数据、平铺
        const studentNowDayDataFlat = studentAll7DaysDataFlatDateGroup[nowDate];
        // 需要展示的科目
        const courseList = uniqBy(studentNowDayDataFlat, (i) => i.course).map(
          (i) => {
            return {
              ...i,
              courseName: getJoinName(i).courseName,
            };
          }
        );
        this.courseSelectList = courseList;
        // 全部
        courseList.unshift({
          course: 0,
          courseName: "全部",
        });
        const computedKoData = (studentData, classData, gradeData) => {
          // 计算学生个人今日数据
          const { markQuestionCt, wrongCt, rightPercent } =
            this.computedRightPercent(studentData);
          // 对齐后端返回正确率的计算精度
          const dRightPercent = rightPercent.toFixed(10);
          // 打败本班
          const koClass = this.toPercent(
            classData.filter((s) => +s.rightPercent <= dRightPercent).length -
              1,
            classData.length - 1,
            true
          );

          // 打败本年级
          const koGrade = this.toPercent(
            gradeData.filter((s) => +s.rightPercent <= dRightPercent).length -
              1,
            gradeData.length - 1,
            true
          );
          return {
            rightPercent: rightPercent.toFixed(1),
            wrongCt,
            markQuestionCt,
            koClass: koClass.toFixed(1),
            koGrade: koGrade.toFixed(1),
          };
        };

        // 计算需要过滤科目的数据, 同时拼接上折线数据
        const finalData = courseList.map(({ course }) => {
          const filterCourse = this.filterCourse;
          // 过滤科目
          const gradeCourse = filterCourse(
            gradeAll7DaysDataDateGroup[nowDate],
            course
          );
          const classCourse = filterCourse(
            classAll7DaysDataDateGroup[nowDate],
            course
          );
          const studentCourse = filterCourse(
            studentAll7DaysDataFlatDateGroup[nowDate],
            course
          );
          const studentData = computedKoData(
            studentCourse,
            classCourse,
            gradeCourse
          );

          // start 折线图7日数据计算,不需要科目过滤
          const days7AllData = date7Day.reduce((p, d) => {
            const studentData = filterCourse(
              studentAll7DaysDataFlatDateGroup[d],
              course
            );
            const classData = filterCourse(
              classAll7DaysDataDateGroup[d],
              course
            );
            const gradeData = filterCourse(
              gradeAll7DaysDataDateGroup[d],
              course
            );
            p[d] = computedKoData(studentData, classData, gradeData);
            return {
              ...p,
              [d]: {
                ...p[d],
                studentData,
                classData,
                gradeData,
              },
            };
          }, {});
          console.log("days7AllData", days7AllData);
          const days7AllDataValue = Object.values(days7AllData);
          // 我的正确率
          const myRightPercentLine = days7AllDataValue.map(
            (i) => i.rightPercent
          );
          // ko班级百分比
          const classRightKoPercentLine = days7AllDataValue.map(
            (i) => i.koClass
          );
          // ko年级百分比
          const gradeRightKoPercentLine = days7AllDataValue.map(
            (i) => i.koGrade
          );
          // end 折线图数据计

          return {
            // 今日科目数据
            course: course,
            ...studentData,
            // 7日科目数据
            myRightPercentLine,
            classRightKoPercentLine,
            gradeRightKoPercentLine,
          };
        });
        this.finalData = finalData;
        console.log(finalData);
        return finalData;
      });
    },
    async initMyWrong(StudentInfo) {
      const res = await listWrongQuestion4Student(StudentInfo);
      const renderQuestions = [];
      res.data.forEach((q) => {
        // 处理 basicQuestions
        if (q.basicQuestions && q.basicQuestions.length > 0) {
          q.basicQuestions.forEach((bq) => {
            const ques = {
              ...q,
              trans: true,
              question: {
                ...bq,
                id: bq.questionId,
                book: q.question.book,
              },
            };
            ques.joinName = getJoinName(ques.question.book).joinName;
            renderQuestions.push(ques);
          });
        } else {
          renderQuestions.push(q);
        }
      });
      console.log(renderQuestions);
      const uniqueRenderQuestions = uniqBy(
        renderQuestions,
        (i) => i.question.id
      );
      const arr = groupBy(uniqueRenderQuestions, (i) => `${i.course}`);

      this.myQuestions = arr;
      console.log(uniqueRenderQuestions);
    },
    isSameStudent(i, j) {
      return !["classNumber", "enterSchoolYear", "phase", "studentNumber"].some(
        (z) => i[z] !== j[z]
      );
    },
    isSameClass(i, j) {
      return !["classNumber", "enterSchoolYear", "phase"].some(
        (z) => i[z] !== j[z]
      );
    },
    toPercent(numerator, denominator, ko) {
      // if (ko && !numerator && denominator === 1) {
      //   return 100;
      // }
      if (!denominator) {
        return 100;
      }
      return (numerator * 100) / denominator;
    },
    filterCourse(arr, i) {
      if (Array.isArray(arr)) {
        const filterArr = arr.filter((j) => !i || j.course === i);
        // 全部科目时，对各科数据进行合并计算
        if (!i) {
          // 学生分组
          const x = groupBy(filterArr, (i) => i.studentNumber);
          // 计算学生整体合并数据
          return Object.values(x).map((st) => this.computedRightPercent(st));
        }
        return filterArr;
      } else {
        const newObj = { ...arr };
        for (let key in newObj) {
          newObj[key] = this.filterCourse(newObj[key], i);
        }
        return newObj;
      }
    },
    computedRightPercent(arr = []) {
      let markQuestionCt = 0;
      let wrongCt = 0;
      arr.forEach((z) => {
        markQuestionCt += z.markQuestionCt;
        wrongCt += z.wrongCt;
      });
      return {
        ...arr[0],
        wrongCt,
        markQuestionCt,
        rightPercent: this.toPercent(markQuestionCt - wrongCt, markQuestionCt),
      };
    },
    courseSelectChange(index) {
      this.courseSelectIndex = index;
    },
    getCutImages(attaches = []) {
      return attaches.map((attache) => {
        return (
          `${ossUrl(
            attache.page.picPath || attache.page.answerPicPath
          )}?x-oss-process=image/crop,` +
          `x_${parseInt(attache.ltx)},` +
          `y_${parseInt(attache.lty)},` +
          `w_${parseInt(attache.width)},` +
          `h_${parseInt(attache.height)}`
        );
      });
    },
    toExplain(item) {
      this.$refs.VideoExplainPlayer.open({
        explains: item.question.explains,
      });
    },
    toLogin() {
      this.$router.push({
        path: "/smartHomeworkUserClient/userCenter/studentInfo",
        query: {
          login: true,
        },
      });
    },
    toWrongBooks() {
      this.$router.push({
        path: "/smartHomeworkUserClient/statisticalData/wrongBooks",
      });
    },
  },
})
export default class smartHomeworkUserClient extends Vue {}
</script>

<style lang="scss">
.statisticalDataWrap {
  height: 100vh;
  overflow: auto;

  .userCard {
    width: 375px;
    height: 139px;
    padding-top: 32px;
    background-image: url("https://img01.yzcdn.cn/upload_files/2022/05/09/FmzGzFuGLSMwr0eywgKvOWcUS5Gm.png");
    background-size: 100% 100%;

    .userHead {
      margin-left: 32px;

      img {
        width: 56px;
      }
    }

    .userMessage {
      margin-left: 21px;

      .userM1 {
        text-align: left;
        color: #ffffff;
        font-size: 12px;

        span {
          &:first-child {
            font-size: 18px;
            margin-right: 12px;
          }
        }
      }

      .userM2 {
        margin-top: 7px;
        text-align: left;

        span {
          display: inline-block;
          padding: 0 12px;
          height: 22px;
          margin-right: 6px;
          line-height: 22px;
          border-radius: 90px;
          font-size: 12px;
          color: #e2eeff;
          background: #4f99ff;
        }
      }
    }

    .loginBut {
      margin-left: 16px;
      width: 92px;
      height: 31px;
      font-size: 13px;
      color: #117fff;
      background: #e3efff;
      border-radius: 90px;
    }
  }

  .dataContent {
    position: relative;
    min-height: 50vh;
    background: #ffffff;
    padding-bottom: 20px;

    .changeTitle {
      .tImg {
        width: 83px;
        margin-left: 19px;
      }

      .courseSelect {
        text-align: right;
        margin-right: 11px;

        .tabItem {
          position: relative;
          display: inline-block;
          width: 50px;
          text-align: center;
          font-size: 14px;
          color: #999999;

          &.selected {
            font-size: 18px;
            color: #3f90ff;

            &::after {
              content: "";
              position: absolute;
              bottom: -3px;
              left: 50%;
              transform: translateX(-50%);
              width: 16px;
              height: 3px;
              background: #3f90ff;
              border-radius: 3px;
            }
          }
        }
      }
    }

    .rightRate {
      position: relative;
      margin: 33px auto auto;
      width: 335px;
      height: 141px;
      background: #f3f8ff;
      border-radius: 10px;

      .rightData {
        padding-top: 20px;

        .dataContentWrap {
          flex: 1;
          font-size: 12px;
          color: #999999;

          p {
            &:first-child {
              font-weight: 500;
              font-size: 20px;
              color: #3f90ff;
            }
          }
        }

        .line {
          height: 25px;
          border: 1px solid #d1e8ff;
        }
      }

      .rightRank {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 335px;
        height: 63px;

        img {
          width: 100%;
        }

        .t1 {
          position: absolute;
          font-size: 12px;
          color: #ffffff;
          top: 16px;
          left: 105px;
        }

        .classKo {
          position: absolute;
          bottom: 7px;
          font-weight: 500;

          &.left {
            left: 133px;
            transform: translateX(-50%);
          }

          &.right {
            left: 230px;
            transform: translateX(-50%);
          }

          span {
            &:first-child {
              font-size: 12px;
              color: #ffffff;
            }

            &:last-child {
              font-size: 13px;
              color: #fddf75;
            }
          }
        }
      }
    }

    .historyData {
      width: 335px;
      margin: 36px auto auto;

      .commonTitle {
        .tLine {
          height: 10px;
          width: 2px;
          border-radius: 1px;
          background: #3f90ff;
        }

        h3 {
          margin-left: 8px;
          font-weight: 500;
          font-size: 14px;
          color: #202020;
        }
      }

      .commonContent {
        margin-top: 11px;
        min-height: 200px;

        .charts-box {
          height: 200px;
          @media (max-device-width: 320px) {
            height: 250px !important;
          }
        }
      }
    }

    .myQuestion {
      margin-top: 20px;

      .questionList {
        .questionItem {
          width: 335px;
          background: #f2f7ff;
          border-radius: 10px;
          margin-bottom: 14px;
          padding: 12px 10px 16px 10px;

          .questionName {
            text-align: left;
            font-size: 13px;
            color: #000000;
          }

          .questionImages {
            margin-top: 12px;

            img {
              width: 315px;
              border-radius: 5px;
              margin-bottom: 10px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }

          .questionData {
            margin-top: 14px;

            .questionRate {
              p {
                text-align: left;

                &:first-child {
                  font-size: 12px;
                  color: #3f90ff;
                }

                &:last-child {
                  margin-top: 1px;
                  color: #999999;
                  font-size: 11px;
                }
              }
            }

            .questionExplain {
              width: 85px;
              height: 30px;
              font-size: 12px;
              color: #ffffff;
              background: linear-gradient(
                180deg,
                #59a1ff 5.21%,
                #3f90ff 95.31%
              );
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
