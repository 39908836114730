import { Module } from "vuex";
import RootState from "@/store/typing";
import { getStudentInfo } from "@/project/smartHomeworkUserClient/API/user";
import { phaseDic } from "@/project/smartHomeworkUserClient/common/dictionary";
import { getGradesInfo } from "@/static/js/zjs";
import { userState } from "@/project/smartHomeworkUserClient/store/typing";

export const state: userState = {
  studentUserInfo: {},
  zidian: {},
  wrongQuestionsBookGradeVolumnText: "",
};
const user: Module<userState, RootState> = {
  namespaced: true,
  state,
  mutations: {
    setStudentUserInfo(state, payload) {
      state.studentUserInfo = {
        ...state.studentUserInfo,
        ...payload,
      };
    },
    getZdian(state, payload) {
      state.zidian = payload;
    },
  },
  actions: {
    async getStudentUserInfo({ state, commit }, isForceGet = false) {
      if (state.studentUserInfo.mobile && !isForceGet) {
        return state.studentUserInfo;
      }
      const res = await getStudentInfo();
      if (!res.data || !res.data.enterSchoolYear) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { name } = getGradesInfo({
        startYear: res.data.enterSchoolYear,
        studySection: phaseDic[res.data.phase],
      });
      res.data.gradeName = name;
      commit("setStudentUserInfo", res.data);
      return res.data;
    },
  },
};
export default user;
