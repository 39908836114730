import request from "@/request";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getzdChildV2(data) {
  return request({
    url: "/api-user-acl/open/dictionary/getAllChildren/v2",
    method: "post",
    data: data,
  });
}
