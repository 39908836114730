import { Router } from "vue-router";
import { loginWx } from "@/permisson/wxOauth2";
import store from "@/store";

export default function loginSnsapiBaseRouterPlugin(router: Router): void {
  router.beforeEach(async (to, from, next) => {
    const openid = await loginWx();
    store.commit("setOpenid", openid);
    next();
  });
}
