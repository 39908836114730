export const PLATFORM_NAME = {
  HEBEI: "hebei",
  GUANGDONG: "guangdong",
  SHANXI: "shanxi",
  LIAONING: "liaoning",
  RENRENTONG: "rrt",
  SMART: "smart",
};

export interface Platform {
  platform: string;
  appid: string;
}

export function getPlatformInOpen(): Platform {
  let appid, platform;
  const URL = "https://" + window.location.host;
  // 通过域名设定appid
  if (URL.indexOf("weixin.zijinshe") > -1) {
    // 河北子衿 跳转首页
    appid = "wx43cddada7b553cec";
    platform = PLATFORM_NAME.HEBEI;
  } else if (URL.indexOf("gd.leziedu") > -1) {
    // 南方子衿
    appid = "wx7c231ad9bef89b79";
    platform = PLATFORM_NAME.GUANGDONG;
  } else if (URL.indexOf("edu.leziedu") > -1) {
    // 山西乐子
    appid = "wxa432270a25dd972f";
    platform = PLATFORM_NAME.SHANXI;
  } else if (
    URL.indexOf("liaoning.leziedu") > -1 ||
    URL.indexOf("liaoning.zijinshe") > -1
  ) {
    // 辽宁
    appid = "wxdbffc41fa60453a9";
    platform = PLATFORM_NAME.LIAONING;
  } else {
    // 人人通
    platform = PLATFORM_NAME.RENRENTONG;
    appid = "wx57ddebee5ae12fbc";
  }
  return {
    platform: platform,
    appid: appid,
  };
}

// 三方平台下的获取平台，从链接中携取appid。
export function getPlatformInThird(): Platform | undefined {
  const host = window.location.host;
  const appid = host.split(".")[0];
  const dict: Record<string, Platform> = {
    wx57ddebee5ae12fbc: {
      platform: PLATFORM_NAME.RENRENTONG,
      appid: appid,
    },
    wxa19ae32ad170f549: {
      platform: PLATFORM_NAME.SMART,
      appid,
    },
  };
  return dict[appid];
}
