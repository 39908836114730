<template>
  <div>
    <van-popup v-model:show="show" style="background: none" position="top">
      <div class="videoWrap">
        <AliPlayerV3 v-if="show" :options="options" @ended="videoEnd" />
        <van-icon
          color="#ffffff"
          style="margin-top: 80px"
          size="30"
          name="close"
          @click="close"
        />
      </div>
    </van-popup>
    <van-popup
      v-model:show="showConfirm"
      position="center"
      style="background: none"
    >
      <div class="confirmWrap flex-col">
        <div class="confirmTitle flex-col flex-center">
          <div class="t1">视频讲解</div>
          <div class="t2">这道题你学会了吗？</div>
          <van-icon class="close" name="cross" />
        </div>
        <div class="confirmContent flex-col flex-center">
          <div class="b1 flex-center" @click="know">是，我学会了</div>
          <div class="b2 flex-center" @click="noKnow">否，看详细讲解</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
/*
 * By Suxue
 * 2022/5/10
 */
import AliPlayerV3 from "@/components/VueAliPlayer";
import { getAliVideoUrl } from "@/API/aliVideo";
import { getOssBookQuestionJSON } from "@/API/ossBook";
import { Dialog } from "vant";
export default {
  name: "VideoExplainPlayer",
  components: { AliPlayerV3 },
  data() {
    return {
      show: false,
      src: "",
      step: 1,
      showConfirm: false,
      videos: [],
    };
  },
  computed: {
    options() {
      return {
        source: this.videos[this.step - 1],
      };
    },
  },
  methods: {
    async open({ explains = [], bookId, chapterId, questionId }) {
      let aliExplains = explains.filter((i) => i.mediaType === "video_vod");
      if (chapterId) {
        const res = await getOssBookQuestionJSON(bookId, chapterId, questionId);
        console.log(res);
        if (res.explains) {
          aliExplains = res.explains.filter((i) => i.mediaType === "video_vod");
        }
      }
      console.log(aliExplains);
      if (aliExplains.length < 1) {
        return Dialog.confirm({
          title: "提示",
          message:
            "<div class='dialogMes'><img src='https://img01.yzcdn.cn/upload_files/2022/05/13/FobsCS_4oSLVAvZI7hdr9zRbdSfr.png'/>当前题目暂无讲解，您可联系客服登记您的讲解需求！</div>",
          cancelButtonColor: "#333333",
          cancelButtonText: "取消",
          confirmButtonColor: "#4593FF",
          confirmButtonText: "客服咨询",
          allowHtml: true,
        })
          .then(() => {
            // on confirm
          })
          .catch(() => {
            // on cancel
          });
      }
      const firstVideo = aliExplains.find((i) =>
        chapterId ? i.sort1 : i.sort === 1
      );
      const secondVideo = aliExplains.find((i) =>
        chapterId ? i.sort1 : i.sort === 2
      );
      const urls = await this.getPlayUrls(
        [firstVideo, secondVideo].filter(Boolean)
      );
      this.videos = urls;
      this.step = 1;
      this.show = true;
    },
    async getPlayUrls(explains) {
      const videos = explains.map((i) => i.mediaSource);
      const res = await getAliVideoUrl(videos.join(","));
      console.log(res);
      return videos
        .map((i) => {
          return res[i];
        })
        .filter(Boolean);
    },
    videoEnd(e) {
      console.log(e);
      if (this.step === 1 && this.videos.length > 1) {
        this.showConfirm = true;
      }
    },
    know() {
      this.showConfirm = false;
      this.show = false;
    },
    noKnow() {
      this.showConfirm = false;
      this.step += 1;
    },
    close() {
      this.show = false;
      this.showConfirm = false;
    },
  },
};
</script>

<style lang="scss">
.dialogMes {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 14px;
  color: #333333;
  img {
    width: 17px;
    height: 17px;
    margin-left: 10px;
    margin-top: 1px;
  }
}
.confirmWrap {
  width: 267px;
  height: 276px;
  border-radius: 15px;
  .confirmTitle {
    position: relative;
    height: 89px;
    background-image: url("https://img01.yzcdn.cn/upload_files/2022/05/12/FoyOGJwxQIaz5uMu6pV38343-pqz.png");
    background-size: 100%;
    .t1 {
      position: relative;
      z-index: 2;
      font-size: 14px;
      color: #ffffff;
      &:before {
        content: "";
        bottom: 0;
        left: -2px;
        position: absolute;
        z-index: -1;
        width: 64px;
        height: 9px;
        background: #4090ff;
        opacity: 0.29;
        border-radius: 9px;
      }
    }
    .t2 {
      margin-top: 5px;
      font-size: 20px;
      color: #ffffff;
    }
    .close {
      position: absolute;
      top: 11px;
      right: 12px;
      font-size: 18px;
      color: #ffffff;
    }
  }
  .confirmContent {
    background: #ffffff;
    border-radius: 0 0 15px 15px;
    flex: 1;
    .b1 {
      width: 200px;
      height: 45px;
      border: 1px solid #388bff;
      border-radius: 30px;
      font-size: 14px;
      color: #388bff;
    }
    .b2 {
      margin-top: 20px;
      width: 200px;
      height: 45px;
      background: linear-gradient(180deg, #5ea1ff 0%, #2882ff 100%);
      border-radius: 100px;
      font-size: 14px;
      color: #ffffff;
    }
  }
}
</style>
