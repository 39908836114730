/* eslint-disable */
export * from "./load";
export { uniqBy } from "lodash";

export function urlSearch(obj) {
  const keys = Object.keys(obj) || [];
  return keys.reduce((pre, now, index) => {
    return `${pre}${index === 0 ? "" : "&"}${now}=${String(obj[now])}`;
  }, "?");
}

export function getQueryStr(name, queryUrl) {
  var url = queryUrl || window.location.search;
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = url.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}

export function getgradesinfo(option) {
  var grades = [];
  var grade = {};
  var date = new Date();
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  // 如果当前月份大于等于9 则一年级入学年份为今年 否则为去年
  if (month >= 9) {
    grade = {};
    grade.startYear = year;
    grade.studySection = "小学";
    grade.name = "一年级";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 1;
    grade.studySection = "小学";
    grade.name = "二年级";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 2;
    grade.studySection = "小学";
    grade.name = "三年级";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 3;
    grade.studySection = "小学";
    grade.name = "四年级";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 4;
    grade.studySection = "小学";
    grade.name = "五年级";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 5;
    grade.studySection = "小学";
    grade.name = "六年级";
    grades.push(grade);

    grade = {};
    grade.startYear = year;
    grade.studySection = "初中";
    grade.name = "七年级";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 1;
    grade.studySection = "初中";
    grade.name = "八年级";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 2;
    grade.studySection = "初中";
    grade.name = "九年级";
    grades.push(grade);

    grade = {};
    grade.startYear = year;
    grade.studySection = "高中";
    grade.name = "高一";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 1;
    grade.studySection = "高中";
    grade.name = "高二";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 2;
    grade.studySection = "高中";
    grade.name = "高三";
    grades.push(grade);
  } else {
    grade = {};
    grade.startYear = year - 1;
    grade.studySection = "小学";
    grade.name = "一年级";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 2;
    grade.studySection = "小学";
    grade.name = "二年级";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 3;
    grade.studySection = "小学";
    grade.name = "三年级";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 4;
    grade.studySection = "小学";
    grade.name = "四年级";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 5;
    grade.studySection = "小学";
    grade.name = "五年级";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 6;
    grade.studySection = "小学";
    grade.name = "六年级";
    grades.push(grade);

    grade = {};
    grade.startYear = year - 1;
    grade.studySection = "初中";
    grade.name = "七年级";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 2;
    grade.studySection = "初中";
    grade.name = "八年级";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 3;
    grade.studySection = "初中";
    grade.name = "九年级";
    grades.push(grade);

    grade = {};
    grade.startYear = year - 1;
    grade.studySection = "高中";
    grade.name = "高一";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 2;
    grade.studySection = "高中";
    grade.name = "高二";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 3;
    grade.studySection = "高中";
    grade.name = "高三";
    grades.push(grade);
  }
  grades.forEach((item, index) => {
    item.index = index;
  });
  if (option) {
    const { startYear, studySection } = option;
    const res = grades.find((item) => {
      return (
        startYear &&
        item.startYear === startYear &&
        studySection &&
        item.studySection === studySection
      );
    });
    return res ? { name: res.name, index: res.index } : "";
  }
  return grades;
}

export function unique(arr, u_key) {
  const map = new Map();
  arr.forEach((item) => {
    if (!map.has(item[u_key])) {
      map.set(item[u_key], item);
    }
  });
  return [...map.values()];
}

export function getformData(obj) {
  return Object.keys(obj).reduce((pre, cur) => {
    pre.append(cur, obj[cur]);
    return pre;
  }, new FormData());
}

export const qs = getformData;

export function setCookie(name, value, time) {
  var Days = time || 30;
  var exp = new Date();
  exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
  document.cookie =
    name +
    "=" +
    escape(value || "") +
    ";expires=" +
    exp.toGMTString() +
    ";path=/";
}

export function getCookie(name) {
  var arr;
  var reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  const result = (arr = document.cookie.match(reg)) ? unescape(arr[2]) : "";
  return decodeURIComponent(result);
}

export function delCookie(name) {
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  var cval = getCookie(name);
  var hostname = window.location.hostname;
  if (cval != null) {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.${hostname
      .split(".")
      .slice(-2)
      .join(".")}`;
  }
}

export function scrollBottom(cb, uninstall) {
  if (uninstall) {
    document.removeEventListener("scroll", window.SCROLLEVENTS);
    return;
  }

  function getScrollTop() {
    var scrollTop = 0;
    var bodyScrollTop = 0;
    var documentScrollTop = 0;
    if (document.body) {
      bodyScrollTop = document.body.scrollTop;
    }
    if (document.documentElement) {
      documentScrollTop = document.documentElement.scrollTop;
    }
    scrollTop =
      bodyScrollTop - documentScrollTop > 0 ? bodyScrollTop : documentScrollTop;
    return scrollTop;
  }

  function getScrollHeight() {
    var scrollHeight = 0;
    if (document.body) {
      var bSH = document.body.scrollHeight;
    }
    if (document.documentElement) {
      var dSH = document.documentElement.scrollHeight;
    }
    scrollHeight = bSH - dSH > 0 ? bSH : dSH;
    return scrollHeight;
  }

  function getWindowHeight() {
    var windowHeight = 0;
    if (document.compatMode === "CSS1Compat") {
      windowHeight = document.documentElement.clientHeight;
    } else {
      windowHeight = document.body.clientHeight;
    }
    return windowHeight;
  }

  window.SCROLLEVENTS = function () {
    if (getScrollTop() + getWindowHeight() === getScrollHeight()) {
      cb();
    }
  };
  document.addEventListener("scroll", window.SCROLLEVENTS);
}

export function formatDate(fmt = "yyyy-MM-dd HH:mm:ss", formatDate) {
  let date = formatDate;
  if (typeof date === "string" && date.includes("-")) {
    date = date.replace(/\-/g, "/");
  }
  date = date ? new Date(date) : new Date();
  const o = {
    "M+": date.getMonth() + 1, // 月份
    "d+": date.getDate(), // 日
    "H+": date.getHours(), // 小时
    "m+": date.getMinutes(), // 分
    "s+": date.getSeconds(), // 秒
    "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (const k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return fmt;
}

function LockScroll(element) {
  this.el = element || document.getElementsByTagName("body")[0];
  this.open = false;
  this.scrollTop = document.documentElement.scrollTop;
  this.position = this.el.position || "static";
}

LockScroll.prototype = {
  lock() {
    const body = document.querySelector("body");
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;

    body.style.position = `fixed`;
    body.style.top = `${-scrollTop || 0}px`;
  },
  unLock() {
    const body = document.querySelector("body");
    body.style.position = "static";
    const top = body.style.top;
    const resTop = Math.abs(parseInt(top));
    document.documentElement.scrollTop = resTop;
    document.body.scrollTop = resTop;
  },
};

export function lockScroll(element) {
  return new LockScroll(element);
}

export function saveKey(key, value, expire, message = {}) {
  const nowTime = new Date().valueOf();
  window.localStorage.setItem(
    key,
    JSON.stringify({
      key,
      value,
      expire: expire ? nowTime + expire * 3600 * 24 : null,
      message,
    })
  );
}

export function clearKey(key) {
  if (key) {
    window.localStorage.removeItem(key);
  } else {
    const nowTime = new Date().valueOf();
    setTimeout(function () {
      var len = localStorage.length;
      for (var i = 0; i < len; i++) {
        var getKey = localStorage.key(i);
        var local = localStorage.getItem(getKey);
        let result;
        try {
          result = JSON.parse(local);
        } catch (error) {
          return null;
        }
        if (result && result.expire && nowTime > result.expire) {
          window.localStorage.removeItem(getKey);
        }
      }
    }, 0);
  }
}

export function getKey(key) {
  const nowTime = new Date().valueOf();
  const local = window.localStorage.getItem(key);
  if (!local) {
    return null;
  }
  let result;
  try {
    result = JSON.parse(local);
  } catch (error) {
    return local;
  }
  if (nowTime > result.expire) {
    return null;
  }
  return {
    value: result.value,
    message: result.message,
  };
}

export function checkScanQrCode(code) {
  var checksum = function (number, len) {
    var result = 0;
    for (var i = 0; i < len; i += 2) {
      result += parseInt(number[i]);
    }
    for (var i = 1; i < len; i += 2) {
      result += parseInt(number[i]) * 3;
    }
    return (10 - (result % 10)) % 10;
  };
  var checksum1 = function (data, len) {
    var result = 0;
    for (var i = 0; i < len; i++) {
      result += parseInt(data[i]) * (3 - (i % 2) * 2);
    }
    return Math.ceil(result / 10) * 10 - result;
  };
  var checkCode = function (data) {
    if (data.indexOf(",") > 0) {
      var datas = data.split(",");
      if (datas.length == 2) {
        var type = datas[0].replace("_", "").replace("-", "").toUpperCase();
        var barCode = datas[1];
        if (type == "EAN13") {
          return (
            barCode.search(/^[0-9]{13}$/) !== -1 &&
            barCode[12] == checksum(barCode, 12)
          );
        } else if (type == "EAN2") {
          return barCode.search(/^[0-9]{2}$/) !== -1;
        } else if (type == "EAN5") {
          return barCode.search(/^[0-9]{5}$/) !== -1;
        } else if (type == "EAN8") {
          return (
            barCode.search(/^[0-9]{8}$/) !== -1 &&
            barCode[7] == checksum(barCode, 7)
          );
        } else if (type == "ITF") {
          return barCode.search(/^([0-9]{2})+$/) !== -1;
        } else if (type == "ITF14") {
          return (
            barCode.search(/^[0-9]{14}$/) !== -1 &&
            barCode[13] == checksum1(barCode, 13)
          );
        } else if (type == "UPC" || type == "UPCA") {
          return (
            barCode.search(/^[0-9]{12}$/) !== -1 &&
            barCode[11] == checksum(barCode, 11)
          );
        } else if (type == "CODE39") {
          return barCode.search(/^[0-9A-Z\-\.\ \$\/\+\%]+$/) !== -1;
        } else {
          return barCode.search(/^[A-D][0-9\-\$\:\.\+\/]+[A-D]$/) !== -1;
        }
      }
    } else {
      // return data.search(/^[0-9]{13}$/) !== -1 && data[12] == checksum(data);
      // return data.search(/^[0-9]{13}$/) !== -1;
      return true;
    }
    return false;
  };
  return checkCode(code);
}

// 拼接autho地址
export function autho2(url, query, auth) {
  // 域名
  const URL = "https://" + window.location.host;
  // 通过域名设定appid
  const appid = getPlatform().appid;
  const state = query.state || 1;
  const autho2 = "https://open.weixin.qq.com/connect/oauth2/authorize";
  if (auth) {
    return `${URL}${url}${urlSearch(query)}`;
  }
  if (!isWeiXin()) {
    return `${URL}${url}${urlSearch(query)}`;
  }

  delete query.state;
  url = `${URL}${url}${urlSearch(query)}`;
  return `${autho2}?appid=${appid}&redirect_uri=${encodeURIComponent(
    url
  )}&response_type=code&scope=snsapi_base&state=${state}&connect_redirect=1#wechat_redirect`;
}

export class Popup {
  stack = [];
  step = 0;

  push(fun, index) {
    if (index) {
      this.stack[index - 1] = fun;
    } else {
      this.stack.push(fun);
    }
    return index || this.stack.length;
  }

  next() {
    if (this.stack[this.step]) {
      this.stack[this.step]();
      this.stack[this.step] = null;
      this.step += 1;
    }
  }

  end() {}
}

export function formPost(URL, PARAMTERS) {
  // 创建form表单
  var temp_form = document.createElement("form");
  temp_form.action = URL;
  // 如需打开新窗口，form的target属性要设置为'_blank'
  temp_form.target = "_self";
  temp_form.method = "post";
  temp_form.style.display = "none";
  // 添加参数
  for (var item in PARAMTERS) {
    var opt = document.createElement("textarea");
    opt.name = item;
    opt.value = PARAMTERS[item];
    temp_form.appendChild(opt);
  }
  document.body.appendChild(temp_form);
  // 提交数据
  temp_form.submit();
}

export const PLATFROM_NAME = {
  HEBEI: "hebei",
  GUANGDONG: "guangdong",
  SHANXI: "shanxi",
  LIAONING: "liaoning",
  RENRENTONG: "rrt",
};

export function getPlatform() {
  var appid, platform;
  var URL = "https://" + window.location.host;
  // 通过域名设定appid
  if (URL.indexOf("weixin.zijinshe") > -1) {
    // 河北子衿 跳转首页
    appid = "wx43cddada7b553cec";
    platform = PLATFROM_NAME.HEBEI;
  } else if (URL.indexOf("gd.leziedu") > -1) {
    // 南方子衿
    appid = "wx7c231ad9bef89b79";
    platform = PLATFROM_NAME.GUANGDONG;
  } else if (URL.indexOf("edu.leziedu") > -1) {
    // 山西乐子
    appid = "wxa432270a25dd972f";
    platform = PLATFROM_NAME.SHANXI;
  } else if (
    URL.indexOf("liaoning.leziedu") > -1 ||
    URL.indexOf("liaoning.zijinshe") > -1
  ) {
    // 辽宁
    appid = "wxdbffc41fa60453a9";
    platform = PLATFROM_NAME.LIAONING;
  } else {
    // 人人通
    platform = PLATFROM_NAME.RENRENTONG;
    appid = "wx57ddebee5ae12fbc";
  }
  return {
    platform: platform,
    appid: appid,
  };
}

// 三方平台下的获取平台，从链接中携取appid。
export function getPlatformInThird() {}

export function onHistoryBack(fn) {
  pushHistory();
  window.addEventListener(
    "popstate",
    function (e) {
      fn(e);
    },
    false
  );

  function pushHistory() {
    var state = {
      title: "title",
      url: "#",
    };
    window.history.pushState(state, "title", "#");
  }
}

// 转化url
export function urlChange(url) {
  var index = url.lastIndexOf("/");
  const frontStr = url.substring(0, index + 1);
  const beforeStr = url.substring(index + 1, url.length);
  var newStr = frontStr + "b" + beforeStr;
  return newStr;
}

export function changeDocTitle(title) {
  document.title = title;
  var i = document.createElement("iframe");
  i.src =
    "https://img.yzcdn.cn/upload_files/2020/05/28/FkZc2LgZZQMtY2yWcKtNLglJxgK7.png";
  i.style.display = "none";
  i.onload = function () {
    setTimeout(function () {
      i.remove();
    }, 0);
  };
  document.body.appendChild(i);
}

export function get3MonthBefor() {
  var resultDate, year, month, date, hms;
  var currDate = new Date();
  year = currDate.getFullYear();
  month = currDate.getMonth() + 1;
  date = currDate.getDate();
  hms =
    currDate.getHours() +
    ":" +
    currDate.getMinutes() +
    ":" +
    (currDate.getSeconds() < 10
      ? "0" + currDate.getSeconds()
      : currDate.getSeconds());
  switch (month) {
    case 1:
    case 2:
    case 3:
      month += 9;
      year--;
      break;
    default:
      month += 3;
      break;
  }
  month = month < 10 ? "0" + month : month;
  resultDate = year + "-" + month + "-" + date + " " + hms;

  return new Date(resultDate).getTime();
}

// 获取时间戳，兼容ios  不传获取当前的时间错
export function timestamp(time) {
  if (time) {
    var time = format("yyyy-MM-dd HH:mm:ss", time); // 格式是yyyy-MM-dd HH:mm:ss
    // ios不兼容-   转化为/
    return new Date(time.replace(/-/g, "/")).getTime();
  } else {
    return new Date().getTime();
  }
}

// 数组去重的方法
export function uniqueArr(arr) {
  var newArr = [];
  for (var i = 0; i < arr.length; i++) {
    if (newArr.indexOf(arr[i]) === -1) {
      newArr.push(arr[i]);
    }
  }
  return newArr;
}

// ios浏览器底部返回强制从新刷新js
export function relodPage(fn) {
  window.addEventListener(
    "pageshow",
    (e) => {
      if (
        e.persisted ||
        (window.performance && window.performance.navigation.type == 2)
      ) {
        fn ? fn() : window.location.reload();
      }
    },
    false
  );
}

export function removeURLParameter(url, parameter) {
  var dealUrl = url.split("#")[0];
  var hash = url.split("#")[1];
  var urlparts = dealUrl.split("?");
  if (urlparts.length >= 2) {
    var prefix = encodeURIComponent(parameter) + "=";
    var pars = urlparts[1].split(/[&;]/g);
    for (var i = pars.length; i-- > 0; ) {
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }
    var res = urlparts[0] + (pars.length > 0 ? "?" + pars.join("&") : "");
    if (hash) {
      res = res + "#" + hash;
    }
    return res;
  }
  return url;
}

// 判断是否微信登陆
export function isWeiXin() {
  var ua = window.navigator.userAgent.toLowerCase();
  return ua.match(/MicroMessenger/i) == "micromessenger";
}

// 设置过期时间为次日凌晨
export function setCookieData(name, value) {
  var ctuskytime = new Date();
  ctuskytime.setDate(ctuskytime.getDate() + 1);
  ctuskytime.setHours(0);
  ctuskytime.setMinutes(0);
  ctuskytime.setSeconds(0);
  document.cookie =
    name + "=" + escape(value || "") + ";expires=" + ctuskytime.toGMTString();
}

export function transPrice(price = 0, toYuan = true) {
  if (typeof price !== "number") {
    return "";
  }
  const priceY = toYuan ? price / 100 : price;
  if (priceY === 0) {
    return priceY;
  }
  return priceY.toFixed(2);
}

// 一位数字显示为两位，不足两位前面加“0”。num 传入数字 length要显示的位数长度
export function fix(num, length) {
  return ("" + num).length < length
    ? (new Array(length + 1).join("0") + num).slice(-length)
    : "" + num;
}

export function groupBy(array, f) {
  const groups = {};
  array.forEach(function (o) {
    const group = JSON.stringify(f(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return Object.keys(groups).map(function (group) {
    return groups[group];
  });
}

export function compare(name, minor) {
  return function (o, p) {
    var a, b;
    if (o && p && typeof o === "object" && typeof p === "object") {
      a = o[name];
      b = p[name];
      if (a === b) {
        return typeof minor === "function" ? minor(o, p) : 0;
      }
      if (typeof a === typeof b) {
        return a < b ? -1 : 1;
      }
      return typeof a < typeof b ? -1 : 1;
    } else {
      // eslint-disable-next-line no-throw-literal
      throw "error";
    }
  };
}

export function getGradesInfo(option) {
  console.log(option);
  var grades = [];
  var grade = {};
  var date = new Date();
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  // 如果当前月份大于等于9 则一年级入学年份为今年 否则为去年
  if (month >= 9) {
    grade = {};
    grade.startYear = year;
    grade.studySection = "小学";
    grade.name = "一年级";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 1;
    grade.studySection = "小学";
    grade.name = "二年级";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 2;
    grade.studySection = "小学";
    grade.name = "三年级";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 3;
    grade.studySection = "小学";
    grade.name = "四年级";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 4;
    grade.studySection = "小学";
    grade.name = "五年级";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 5;
    grade.studySection = "小学";
    grade.name = "六年级";
    grades.push(grade);

    grade = {};
    grade.startYear = year;
    grade.studySection = "初中";
    grade.name = "七年级";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 1;
    grade.studySection = "初中";
    grade.name = "八年级";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 2;
    grade.studySection = "初中";
    grade.name = "九年级";
    grades.push(grade);

    grade = {};
    grade.startYear = year;
    grade.studySection = "高中";
    grade.name = "高一";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 1;
    grade.studySection = "高中";
    grade.name = "高二";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 2;
    grade.studySection = "高中";
    grade.name = "高三";
    grades.push(grade);
  } else {
    grade = {};
    grade.startYear = year - 1;
    grade.studySection = "小学";
    grade.name = "一年级";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 2;
    grade.studySection = "小学";
    grade.name = "二年级";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 3;
    grade.studySection = "小学";
    grade.name = "三年级";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 4;
    grade.studySection = "小学";
    grade.name = "四年级";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 5;
    grade.studySection = "小学";
    grade.name = "五年级";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 6;
    grade.studySection = "小学";
    grade.name = "六年级";
    grades.push(grade);

    grade = {};
    grade.startYear = year - 1;
    grade.studySection = "初中";
    grade.name = "七年级";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 2;
    grade.studySection = "初中";
    grade.name = "八年级";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 3;
    grade.studySection = "初中";
    grade.name = "九年级";
    grades.push(grade);

    grade = {};
    grade.startYear = year - 1;
    grade.studySection = "高中";
    grade.name = "高一";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 2;
    grade.studySection = "高中";
    grade.name = "高二";
    grades.push(grade);
    grade = {};
    grade.startYear = year - 3;
    grade.studySection = "高中";
    grade.name = "高三";
    grades.push(grade);
  }
  grades.forEach((item, index) => {
    item.index = index;
  });
  if (option) {
    const { startYear, studySection } = option;
    const res = grades.find((item) => {
      return (
        startYear &&
        item.startYear === startYear &&
        studySection &&
        item.studySection === studySection
      );
    });
    return res ? { name: res.name, index: res.index } : "";
  }
  return grades;
}
